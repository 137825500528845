@charset "UTF-8";

@-ms-viewport {
  width: device-width;
}

@keyframes top-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(59, 152, 255, 0.3)
	}
	75% {
		box-shadow: 0 0 0 10px rgba(4, 169, 245, 0)
	}
	100% {
		box-shadow: 0 0 0 0 rgba(4, 169, 245, 0)
	}
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #ccc8;
  border-radius: 4px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
}

::-webkit-scrollbar-thumb:hover {
  background: #aaaf;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 10px;
  background: #fff3;
}

body {
  line-height: 1.8;
  font-size: 18px;
  background: #f5f5f5;
  font-weight: 300;
  cursor: url(https://ipfs.4everland.xyz/ipfs/bafkreihm4v5gpjkv5lboaqhdfmou4mlpvfjkgbn24ywrfivdk7tolgzkke), default;;
  overflow-x: hidden;
  margin: 0;
  -webkit-text-size-adjust: none;
}

a {
  text-decoration: none;
  font-weight: 400;

  &:hover {
    outline: 0;
    background-color: transparent;
    cursor: pointer;
    color: #51aded;
  }

  &:focus {
    outline: 0;
    text-decoration: none;
  }
}

abbr[data-original-title], abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

abbr[title] {
  border-bottom: 1px dotted;
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.43;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

b,
strong {
  font-weight: 700;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: crosshair;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

blockquote {
  font-size: 17.5px;
  margin: 12px 0;
  border-left: 5px solid;
  padding: 4px 0 5px 10px;
  border-radius: 5px;

  footer:before {
    content: '\2014 \00A0';
  }

  .small, small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;

    &:before {
      content: '\2014 \00A0';
    }
  }

}

blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left
}

cite {
  font-style: normal
}

code,
pre,
samp {
  font-family: "Fira Code", Consolas, "Courier New", monospace;
}

dfn {
  font-style: italic
}

dl {
  margin-top: 0;
  margin-bottom: 20px
}

dd,
dt {
  line-height: 1.42857143
}

dt {
  font-weight: 700
}

dd {
  margin-left: 0
}

fieldset {
  padding: .35em .625em .75em;
  margin: 0 2px;
  border: 1px solid silver;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
  transition: .5s;
}

h1, h2, h3,
h4, h5, h6 {
  font-weight: 400;
  margin: 0 0 15px 0;
  font-family: inherit;
  line-height: 1.1;
}

h1, h2, h3 {
  margin-top: 20px;
  margin-bottom: 10px;

  small {
    font-size: 65%;
  }
}

h4, h5, h6 {
  margin-top: 25px;
  margin-bottom: 10px;

  small {
    font-size: 75%;
  }
}

h {
  &1 {
    font-size: 1.5em;
  }
  &2 {
    font-size: 1.3em;
  
    &.title-h2 {
      margin: 15px -20px;
      padding: 0 20px;
      border-left: 5px solid #51aded;
      font-size: 20px;
      line-height: 40px;
      font-size: 18pt;
    }
  }
  &3 {
    font-size: 1.17em;
  }
  &4 {
    font-size: 1em;
  }
  &5 {
    font-size: .83em;
  }
  &6 {
    font-size: .67em;
  }
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

img {
  vertical-align: middle;
}

code, kbd {
  font-size: 90%;
  color: #fff;
  border-radius: 3px;
  padding: 1px 6px;
  background-color: #62b6e7;
  white-space: normal;
}

// prismjs 或 无高亮模式 或 highlightjs的nowarp模式
pre:has(code) {
  border-radius: 8px;
  padding-top: 30px!important;
  position: relative;
  overflow: auto;
  background-color: #1d1f21;
  font-size: 14px;
  font-weight: unset;

  &::before {
      content: " ";
      position: absolute;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background-color: #fc625d;
      width: 12px;
      height: 12px;
      left: 10px;
      top: 10px;
      -webkit-box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
      box-shadow: 20px 0 #fdbc40, 40px 0 #35cd4b;
  }
}

pre code {
  white-space: pre;
  background-color: inherit;
  font-size: inherit;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  // color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

mark {
  padding: .4px 4px;
  // background-color: #9e9e9e;
  // color: #000;
}

p, ol, ul {
  margin: 0 0 1em 0;
  font-size: 16px;
}

ol, ul {

  ol, ul {
    margin-bottom: 0;
  }
}

optgroup {
  font-weight: 700
}

ruby {
  // background: #f2f2f2;
  margin: 0 2px;
  padding: 0 2px;
  border-radius: 4px
}

select {
  [multiple], [size] {
    height: auto
  }
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555
}

small {
  font-size: 85%
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline
}

sup {
  top: -.5em
}

sub {
  bottom: -.25em
}

svg:not(:root) {
  overflow: hidden
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  margin: 2em 0;
  width: 100%;

  *[class*=col-] {
    position: static;
    float: none;
  }

  col[class*=col-] {
    display: table-column;
  }

  td, th {
    padding: 6px 12px;
    border: 1px solid #ccc;
    font-size: 14px;

    [class*=col-] {
      display: table-cell;
    }
  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  -webkit-transition: .3s;
  transition: .3s;

  &:active, &:focus, &:hover {
    background: #393e46 !important;
    color: #fff;
    outline: 0 !important;
    cursor: pointer
  }

  &.btn-primary {
    border: transparent;
    border-color: #51aded;
    background-color: #51aded;
    color: #fff;

    &:hover {
      border-color: #15b9fa;
      background-color: #15b9fa;
    }

    &:active, &:focus {
      border-color: #0895cb;
      background-color: #0895cb;
    }
  }
}

.clearfix, .container, .nav, .row {
  &::before, &::after {
    display: table;
    content: " ";
  }
  &::after {
    clear: both;
  }
}

[class*=col-md-] {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.form {
  &-control {
    padding: 6px 12px;
    line-height: 1.42857143;
    width: 100%;
    color: #555;
    z-index: 2;
    float: left;
  }

  &-group {
    margin-bottom: 15px;
  }
}

.hidden {
  display: none !important
}

.input-group {
  display: flex;
  margin: 30px auto;
  border-collapse: separate;

  &-addon {
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;

    &:first-child {
      border-right: 0;
    }
  }
}

.post-pwd {
  width: 55%
}

.pull, .pullquote {
  &-left, .left {
    float: left !important;
  }
  &-right, .right {
    float: right !important;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px
}

.useragent {
  font-size: 15px;
  color: #333
}

[class*=visible-] {
  display: none !important
}

.text- {
  &left {
    text-align: left;
  }
  &right {
    text-align: right;
  }
  &center {
    text-align: center;
  }
  &justify {
    text-align: justify;
  }
  &nowrap {
    white-space: nowrap;
  }
  &lowercase {
    text-transform: lowercase;
  }
  &uppercase {
    text-transform: uppercase;
  }
  &capitalize {
    text-transform: capitalize;
  }
}

.alert {
  padding: 1rem;
  margin: 2rem 0;
  border: 1px solid transparent;
  display: flex;
  flex-direction: row;

  .icon, .text {
    display: flex;
  }

  .icon {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #fffc;
    border: 1px solid;
    justify-content: center;
    align-items: center;
    margin-right: .5rem;
  }

  .text {
    flex: 1;
    border-left: 1px dashed;
    flex-direction: column;
  }

  a {
    color: #1e8cdb;
  }

  ul, p {
    margin: 0 1em!important;
  }
  p {
    &+p {
      margin-top: .5em!important;
    }
  }

  &-primary {
    background-color: #cfe2ff;
    border-color: #b6d4fe;
    
    .icon, .text p {
      color: #084298!important;
    }
  }

  &-success {
    background-color: #d1e7dd;
    border-color: #badbcc;
    
    .icon, .text p {
      color: #0f5132!important;
    }
  }

  &-danger {
    background-color: #f8d7da;
    border-color: #f5c2c7;

    .icon, .text p {
      color: #842029!important;
    }
  }

  &-info {
    background-color: #cff4fc;
    border-color: #b6effb;

    .icon, .text p {
      color: #055160!important;
    }
  }

  &-warning {
    background-color: #fff3cd;
    border-color: #ffecb5;
    
    .icon, .text p {
      color: #664d03!important;
    }
  }
}

.panel {
  margin-bottom: 20px;
  border: 1px solid transparent;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);

  &-body {
    padding: 15px;
  }
  &-title {
    padding-left: 15px;
  }
  &-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
  }

  > .panel-title {
    color: #fff;
  }

  &-danger {
    border-color: #e15554;

    > .panel-title {
      border-color: #e15554;
      background-color: #e15554;
    }
  }
  &-success {
    border-color: #3bb273;

    > .panel-title {
      border-color: #3bb273;
      background-color: #3bb273;
    }
  }
  &-info {
    border-color: #4d9de0;

    > .panel-title {
      border-color: #4d9de0;
      background-color: #4d9de0;
    }
  }
  &-warning {
    border-color: #ffc320;

    > .panel-title {
      border-color: #ffc320;
      background-color: #ffc320;
    }
  }

  &-pwd {
    border: 0;
    box-shadow: none;
  }
}

.video-container {
  position: relative;
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 56.25%;
  height: 0;

  :not(:first-child) {
    margin-top: 15px;
  }

  embed, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }
}

.kratos-post-content div.xControl {
  margin-top: 20px;
}

.xControl {
  position: relative;
  margin-bottom: 15px;
  background: #f6f6f6;
  border-radius: 5px;

  > .xHeading {
    position: relative;
    cursor: pointer;
    padding-left: 15px;

    a {
      margin: 0;
      font-size: 16px;
    }

    .xIcon {
      transition: .3s;
      position: absolute;
      right: 15px;
    }
  }

  &.active .xIcon {
    transform: rotate(-45deg)
  }

  .xContent:not(.pre-open) {
    display: none;
  }

  .xContent {
    border-top: 1px solid #e6e6e6;

    .inner {
      padding: 5px 15px;
      color: #333;
    }
  }
}

.hide_notice {
  overflow: hidden;
  padding: 8px 8px 8px 24px;
  background-color: #eee;
  font-size: 18px;
  color: #ffcebb;
  margin-bottom: 15px;

  a {
    color: #bbcdff;
  }
}

.unhide {
  padding: 8px;
  margin-bottom: 15px;

  .info {
    font-size: 12px;
    color: #54ff4e;
  }
}

#snow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none;
  transition: opacity .5s;

  &:not(.disabled) {
    background: rgba(225, 225, 225, .05);
    opacity: 1;
  }

  &.disabled {
    opacity: 0;
  }
}

#kratos-wrapper {
  /*overflow-x:hidden;*/
  position: relative;
}

#offcanvas-menu {
  position: fixed;
  bottom: 0;
  top: 60px;
  z-index: 1000;
  width: 240px;
  padding: 20px;
  overflow-y: auto;
  transition: right .3s;
  -webkit-transition: right .3s;
  background: rgba(42, 42, 42, .9);

  .ul-me {
    padding-bottom: 60px;
  }
  
  ul {
    padding: 0;
    margin: 0;

    .sub-menu {
      display: block !important;
    }

    li {
      padding: 0;
      margin: 0 0 10px 0;
      list-style: none;
  
      a {
        font-size: 16px;
        color: rgba(255, 255, 255, .55);
      }

      > .sub-menu {
        margin-top: 9px;
        padding-left: 20px;
      }

      .current-menu-item {
        > a {
          color: #51aded;
        }
      }
    }
  }
}

#kratos-header {
  position: relative;
  margin-bottom: 0;
  z-index: 5;

  #kratos-mobile-topnav {
  
    .color-logo img {
      max-width: 200px;
      height: 40px;
      margin-top: -5px
    }
  
    .kratos-nav-toggle {
      position: fixed;
      top: 11px;
      right: 15px;
      z-index: 999;
      height: 38px;
    
      i {
        position: relative;
        display: inline-block;
        zoom: 1;
        width: 28px;
        height: 2.5px;
        color: #fff;
        font: 700 14px/.4 Helvetica;
        text-transform: uppercase;
        text-indent: -55px;
        background: #fff;
        -webkit-transition: .2s;
        transition: .2s;
    
        &::before, &::after {
          content: '';
          width: 28px;
          height: 2.5px;
          background: #fff;
          position: absolute;
          left: 0;
          -webkit-transition: .2s;
          transition: .2s
        }
    
        &::before {
          top: -9px;
        }
      
        &::after {
          bottom: -9px;
        }
      }
    }
  
  }

  #kratos-mobile-topnav {
  
    .nav-toggle.toon i {
      background: 0 0;
    
      &::before {
        top: 0;
        height: 2px;
        -webkit-transform: rotateZ(45deg);
        transform: rotateZ(45deg);
      }
    
      &::after {
        bottom: 0;
        height: 2px;
        -webkit-transform: rotateZ(-45deg);
        transform: rotateZ(-45deg);
      }
    }
  }
}

.sf-menu {
  li {
    position: relative;
    i {
      margin-right: .5em;
    }
  }
}

#kratos-menu-wrap {
  float: left;
  .sf-menu {
    a {
      color: #fff;
      padding: 7px 14px;
      font-weight: 400;
      font-size: 15px;
      border: none;
      display: block;
      position: relative;
    }
    > li:hover > a {
      color: #333;
      background: #eee
    }
  }
}

.kratos-topnav {
  padding: 0;
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2;
  background: rgba(40, 42, 44, .6);
  top: 0;
  transition: .5s all;
}

#kratos-desktop-topnav {

  .sf-menu {
    list-style: none;
    margin: 0;
    padding: 0;

    * {
      list-style: none;
    }

    li {
      position: relative;
    }

    > li {
      float: left;

      > ul.sub-menu {
        transition: .3s all ease-in-out;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        top: calc(100% + 6px);
        width: 135px;
        margin-top: 6px;
        padding: 10px 5px;
        background: #fffe;
        border-radius: 5px;
        left: -18px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .15);
      
        a {
          letter-spacing: 0;
          padding: 7px 15px !important;
          line-height: 20px;
          color: #848484;
      
          &:hover {
            color: #51aded;
          }
        }
      
        li a {
          text-align: center
        }

        &::before {
          position: absolute;
          top: -6px;
          left: 62px;
          border-right: 6px solid transparent;
          border-bottom: 6px solid #fffe;
          border-left: 6px solid transparent;
          margin: 0 auto;
          content: '';
        }
      }

      &:hover > ul.sub-menu {
        visibility: visible;
        opacity: 1;
        top: 100%;
      }
    }
  }

  .nav-header {
    position: relative;
    float: left;
    width: 100%;
  }

  .menu-right {
    float: right;
  }
}

body.scroll-down.nav-up #kratos-desktop-topnav {
  top: -41px;
}

.kratos {
  &-cover, &-start {
    position: relative;
    height: 800px;

    &.kratos-hero-2 {
      height: 320px;
    }
  }

  &-cover {
    background-size: cover;
    position: relative;
    background-position: center;

    .btn {
      padding: 1em 5em;
      opacity: .9;
    }

    &.kratos-cover-2 {
      height: 320px;
      background-position: top;
    }

    .desc {
      top: 50%;
      position: absolute;
      width: 100%;
      margin-top: -50px;
      z-index: 2;
      color: #fff;
      text-shadow: 2px 2px 2px #666;

      h2 {
        color: #fff;
        text-transform: none;
        font-size: 40px;
        margin-bottom: 10px;
        display: inline-block;
      }

      p, span {
        color: #fff;
        margin-bottom: 30px;
        font-size: 25px;
        letter-spacing: 1px;
        display: inline-block;

        a {
          color: #fff;

          &:focus, &:hover {
            color: #fff !important;

            &.btn {
              border: 2px solid #fff !important;
              background: 0 0 !important;
            }
          }
        }
      }
    }
  }
}

#kratos-blog-post {
  padding: 20px 0;

  p {
    display: block;
  }

  .aligncenter {
    margin-left: auto;
    margin-right: auto
  }

  .alignleft {
    float: left;
  }
  .alignright {
    float: right;
  }
  .alignnone {
    display: inline;
    vertical-align: text-bottom;
  }
  .row {
    display: flex;

    > * {
      max-width: 100%;
    }

    article {
      margin-bottom: 15px;
    }
  }
}

.pagination {
  padding-left: 0;
  border-radius: 4px;
  margin: 23px 0 7px;

  a, span {
    margin: 0 3px;
    position: relative;
    padding: 6px 12px;
    line-height: 1.5;
    text-decoration: none;
    background-color: #fff;
    color: #626262;
  }

  > span.page-number, > a:hover {
    background-color: #51aded;
    color: #fff !important;
  }

  .page-number, .extend {
    min-width: 36px;
    height: 36px;
    display: inline-block;
  }
}

.post-navigation {
  margin-top: 15px;

  a {
    color: #5a5a5a;
  }

  @mixin nav {
    max-width: 50%;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .1);
    padding: 7px 18px;
  }

  .nav-previous {
    @include nav;
    float: left;
    text-align: left;
  }
  .nav-next {
    @include nav;
    float: right;
    text-align: right;
  }
}

ul.kratos-post-meta {
  padding: 0;

  > li {
    list-style: none;
    display: inline-block;
    font-size: 13px;
  
    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  &:not(:last-child) {
    margin-bottom: 0;
  }
}

.kratos-breadcrumb {
  margin: 10px 25px;
  > a, span {
    padding: 0 5px;
  }
}

.kratos-status {
  margin: 15px 15px 15px 38px;
  border-left: 1px dotted #7b7b7b7b;
  position: relative;

  &.kratos-status-post {
    margin: 0 15px 0 38px;
  }

  > i:first-child {
    position: absolute;
    top: calc(50% - .5em);
    left: -8px;
  }

  &-inner {
    margin-left: 30px;

    footer {
      font-size: 12px;
    }

    .kratos-status-content {
      p {
        margin: 0;
      }
      img {
        max-width: 100%
      }
    }
  }
}

.kratos-hentry {
  margin-bottom: 25px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  border-radius: 0;
  -webkit-transition: all .6s ease;
  transition: all .6s ease;

  &.kratos-post-inner {
    margin-bottom: 0;
  }
}

.container article {
  &:last-of-type {
    margin: 0;
  }
  &.kratos-hentry:hover {
    box-shadow: 0 8px 15px rgba(146, 146, 146, .39);
  }
}

.container article

.kratos-post-nav {
  padding: 10px 20px 10px 20px;
  color: #5a5a5a;
  a {
    color: #5a5a5a;
  }
}

.kratos-post-header-title {
  color: #1c1c1c;
  -webkit-transition: .3s;
  transition: .3s;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.5;
  margin: 20px
}

.kratos-post-inner {
  padding: 25px 10px 18px 10px;

  div:last-child.kratos-copyright {
    margin-bottom: 20px;
  }

  .kratos-copyright {
    background-color: rgba(220, 220, 220, .7);
    margin: 25px 0 0;
    border-radius: 5px;

    h5 {
      margin: 15px;
      color: #666;
      font-size: 13px;
      font-weight: 300;
    }

    a {
      color: #777;
    }
  }
}

.kratos-post-inner-new {
  margin-left: 255px;
  height: 160px;
  padding: 21px 20px 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;

  header .label-link {
    position: relative;
    display: inline-block;
    padding: 4px 6px 3px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: #fff;
    vertical-align: baseline;
    white-space: nowrap;
    background-color: #51aded;
    margin-right: 5px;
    position: relative;
    top: -2px;
    border-radius: 0;
    cursor: pointer;

    &::after {
      position: absolute;
      width: 0;
      height: 0;
      vertical-align: top;
      content: "";
      top: 6px;
      right: -4px;
      border-left: 4px solid #51aded;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
    }

    &:hover {
      color: #fff !important
    }
  }
}

.col-md-8 {
  width: 100%;
}

.kratos-post-content {
  word-break: break-word;
  word-wrap: break-word;
  margin: 10px;

  p {
    margin-bottom: 1em;
    font-size: 16px;
    color: #333;
    margin: 1em;
  }

  img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 1em auto;
    border: 2px solid #bbb;
    border-radius: 2px;
  }

  embed {
    max-width: 100%;
  }

  div {
    margin: auto;
  }

  .caption {
    display: block;
    padding: 5px;
    font-size: 14px;
    text-align: center;
  }

  .kratos-post-inner-toc {
    display: none!important; // So it won't be overwritten by hbe decrypt func
    border: 2px dashed #ddd;
    border-radius: 10px;
    padding-top: 15px;
  }

  @media(max-width:991px) {
    .kratos-post-inner-toc {
      display: block!important; // So it won't be overwritten by hbe decrypt func
    }
  }

  @media(max-width:768px) {
    .kratos-post-inner-toc .toc {
      padding-left: 20px;
    }
  }
}

.kratos-entry-header {
  margin-bottom: 15px
}

.kratos-entry-title {
  margin: 0 0 15px;
  font-size: 30px;

  a {
    color: #1c1c1c;
    -webkit-transition: color .3s;
    transition: .3s;
    text-decoration: none;
    font-size: 25px;

    &:hover {
      color: #618eba;
    }
  }

  &-new {
    display: inline;
    margin: 0;
    font-size: 21px;

    strong {
      margin-left: .5em;
    }

    sub {
      display: inline-block;
    }
  }
}

.kratos-entry-thumb-new {
  position: relative;
  float: left;
  width: 240px;
  height: 145px;
  overflow: hidden;
  margin: 20px;
  padding: 0;
  img {
    position: absolute;
    padding: 2px;
    border: 1px solid #f3f3f3;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.kratos-entry-border-new {

  position: relative;

  .kratos-post-meta-new {
    padding: 3px 20px;
    border-top: 1px solid #f3f3f3;
    line-height: 27px;
    overflow: hidden;
    display: block;
    clear: both;
    font-size: 12px;
    background: rgba(253, 253, 253, .7);

    a i {
      margin-left: .3em;
      margin-right: 2px;
    }

    .read-more {
      margin-right: 0;
    }

    > span > * {
      display: inline-block;
    }
  }

  .stickyimg {
    position: absolute;
    z-index: 2;
    left: 100%;
    margin-left: -65px;
    user-select: none;
  }

  a {
    color: #333;
  }

  .pin-top {
    &.wrapper {
      position: absolute;
      right: -10px;
      top: -10px;
      z-index: 1;
      border-radius: 50%;
      animation: top-pulse 3s infinite;
    }

    &.inner {
      width: 20px;
      height: 20px;
      background-color: #51adeddd;
      border-radius: 50%;

    }
  }
}

.kratos-entry-header {
  margin-bottom: 0;
  
  .kratos-post-meta a {
    color: #777676;
  }

  &-new {
    margin: 3px 0 6px;
  }
}

.kratos-entry-content {
  p {
    font-size: 16px;
  }

  &-new {
    text-overflow: ellipsis;

    p {
      font-size: 15px
    }
  }
}

.kratos-entry-footer {
  color: #9d9d9d;
  margin: 10px;

  .post-like-donate {
    margin-top: 15px;
    margin-bottom: 20px;

    a {
      display: inline-block;
      margin: 0 2px;
      width: 130px;
      height: 40px;
      border: 1px solid #e87461;
      border-radius: 0;
      box-shadow: none;
      color: #e87461;
      text-align: center;
      text-decoration: none;
      font-size: 14.5px;
      line-height: 40px;
      transition: all .3s ease;

      &.read-more:hover {
        background: #e87461;
        color: #fff !important;
      }
    }

    .donate {
      margin-left: 10px;
      border-color: #3496e6;
      color: #3496e6;

      &:hover {
        background-color: #3496e6;
        color: #fff !important;
      }
    }

    .share {
      border-color: #78ce79;
      color: #78ce79;

      &:hover {
        background-color: #78ce79;
        color: #fff !important;
      }

      &-plain {
        width: 30px;
        height: 30px;
        font-size: 14px;

        :hover {
          color: #fff;
        }

        .icon-wrap {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }

        &.qq {
          border-color: #00bfff;
          color: #00bfff;

          &:hover {
            background-color: #00bfff;
          }
        }

        &.qzone {
          border-color: #fece00;
          color: #fece00;

          &:hover {
            background-color: #fece00;
          }
        }

        &.weixin {
          border-color: #38ad5a;
          color: #38ad5a;

          &:hover {
            background-color: #38ad5a;
          }
        }

        &.weibo {
          border-color: #e6162d;
          color: #e6162d;

          &:hover {
            background-color: #e6162d;
          }
        }

        &.twitter {
          border-color: #55acee;
          color: #55acee;

          &:hover {
            background-color: #55acee;
          }
        }

        &.facebook {
          border-color: #3e569b;
          color: #3e569b;

          &:hover {
            background-color: #3e569b;
          }
        }
      }
    }
  }
}

.donate-box {
  .meta-pay {
    margin-top: 12px;
    margin-bottom: 12px;
    color: #333;
  }
  .qr-pay img {
    width: 200px;
    height: 200px;
  }
  .choose-pay {
    margin-top: 6px;

    input {
      display: none;

      &:checked+label {
        border-color: #0092ee;
        color: #0092ee;
      }
    }
  }

  .pay-button {
    margin: 10px 5px;
    width: 132px;
    height: 45px;
    border: 1px solid #e6e6e6;
    text-align: center;
    line-height: 38px;
    cursor: pointer;

    img {
      height: 26px;
    }
  }
}

.d-none {
  display: none
}

.kratos-entry-footer .footer-tag {
  a {
    background-color: #aaa;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    line-height: 13px;
    padding: 2px 5px;
  
    &:focus, &:hover {
      background: #8d8d8d;
      color: #fff !important;
    }
  }

  .pull-date {
    float: right;
    margin-top: 5px;
    font-size: 14px;
  }
}

.share {
  &-wrap {
    display: block;
    margin: 10px 0 -20px;
  }
  &-plain, &-wrap {
    position: relative;
    text-align: center;
  }
  &-plain {
    display: inline-block !important;
    box-sizing: border-box;
    margin: 4px 3px !important;
    padding: 0;
    width: 2pc !important;
    height: 2pc !important;
    border: 1px solid #bdc4d6;
    border-radius: 30px !important;
    color: #bdc4d6;
    white-space: nowrap;
    line-height: 30px !important;
    cursor: pointer;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-width: 1px;
    background-color: transparent;

    .icon-wrap {
      vertical-align: middle;
      text-align: center;
      line-height: 30px
    }

    .share-int {
      position: absolute;
      bottom: 50px;
      left: 50%;
      z-index: 8;
      display: none;
      margin-left: -110px;
      padding: 10px;
      width: 220px;
      border: 1px solid #d8d8d8;
      border-radius: 2px;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, .1);
      color: #555;
      text-align: center;
      opacity: .2;
      -webkit-transition: all .1s ease;
      transition: all .1s ease;

      &:after {
        position: absolute;
        margin-left: -11px;
        bottom: -10px;
        content: '';
        box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, .1);
        background-color: white;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
      }

      canvas, img {
        margin: 15px;
        height: auto;
        max-width: 100%;
      }

      p {
        color: #676767;
        white-space: normal;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 1.5;
      }

      img {
        display: inline-block !important;
      }
    }

    &.pop:hover .share-int {
      display: block;
      opacity: 1;
    }
  }
}

@keyframes widget-title-icon-animation {
    0%, 25%, 50%, 75%, 100% {transform: rotate(0);}
    12%, 63% {transform: rotate(10deg);}
    37%, 88%  {transform: rotate(-10deg);}
}
 
@-webkit-keyframes widget-title-icon-animation {
  0%, 25%, 50%, 75%, 100% {-webkit-transform: rotate(0);}
  12%, 63% {-webkit-transform: rotate(10deg);}
  37%, 88%  {-webkit-transform: rotate(-10deg);}
}

#kratos-widget-area {

  user-select: none;
  
  > .sticky-area {
    top: 56px;
    position: sticky;
    transition: .5s all;
  }

  .widget {
    margin-bottom: 15px;
    padding: 20px 25px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
    border-radius: 0;
  
    &:last-child {
      margin: 0;
    }
  
    > .widget-title {
      position: relative;
      margin: 0 0 10px;
      padding-bottom: 12px;
      border-bottom: 1px solid #e7e7e7;
      font-size: 16px;
  
      &:not(.no-after)::after, > .toc-progress-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2.5px;
        width: 100px;
        background-color: #51aded;
        content: '';
        height: 2px !important
      }
  
      i {
        margin-right: 10px;
      }
    }
  
    &:hover > .widget-title i {
      animation: widget-title-icon-animation .5s linear;
      -webkit-animation: widget-title-icon-animation .5s linear;
    }
  
    img {
      max-width: 100%;
      height: auto;
    }
  
    a {
      color: #666;
      text-decoration: none;
      -webkit-transition: .4s;
      transition: .4s;
  
      &:hover {
        color: #618eba;
      }
    }
  
    li {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  
    .children {
      padding-left: 30px;
    }

    &.widget-kratos {
  
      &-about {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    
        .photo-background {
          -webkit-background-size: cover;
          background-size: cover;
        }
    
        .photo-wrapper {
          width: 100%;
    
          img {
            margin: -50px 0 0;
            width: 90px;
            height: 90px;
            object-fit: cover;
            border: rgba(255, 255, 255, .4) 4px solid;
            border-radius: 50%;
          }
        }
    
        .photo-background {
          height: 120px;
        }
    
        .textwidget {
          margin: 10px 0 30px 0;
        }
    
        p {
          margin-bottom: 1em;
          font-size: 16px;
          margin: 20px;
        }
  
        .site-meta {
          display: flex;
          flex-direction: row;
          justify-content: space-around;

          > .meta-item {
            display: flex;
            flex-direction: column;
            padding: .5rem 1rem;
            border-radius: 5px;
            &:hover {
              background-color: #efefefaa;
            }
            > * {
              display: flex;
              justify-content: center;
            }
          }
        }
      }

      &-ad {
        padding: 5px;
      
        .img-responsive {
          display: block;
          max-width: 100%;
          height: auto;
        }
      
        h4 {
          margin: 10px 20px 5px;
        }
      
        img {
          max-width: 100%;
          height: auto;
        }
      }
      
      &-tags {
        .tag-clouds a {
          display: inline-block;
          margin: 3px 4px;
          padding: 4px 12px;
          border: 1px solid #f0f0f0;
          text-transform: uppercase;
          line-height: 19px;
          background: rgba(251, 251, 251, .7);
          border-radius: 5px
        }
      }

      &-posts {
        .tab-content {
          margin: 0 8px -18px 8px;
          > .tab-pane {
            display: none;
          }
          > .active {
            display: block;
          }
          .list-group a {
            overflow: hidden;
            border-bottom: 1px dashed #b4afaf;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            -webkit-transition: color .3s;
            transition: color .3s;
      
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      
        .list-group {
          padding-left: 0;
          margin-bottom: 20px;
        }
      
        .list-group-item {
          position: relative;
          display: block;
          margin-bottom: 0;
          padding: 8px 0;
          border: 0;
          background-color: unset;
      
          &:first-child {
            margin-top: 1px;
          }
      
          i {
            margin: 0 5px 0 0;
          }
        }
      }

      &-archive, &-categories {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        li {
          &::before {
            font-family: fontawesome;
            font-size: 15px;
            margin-right: 8px;
          }
        }
      }
      
      &-archive li::before {
        content: '\f133';
      }
      
      &-categories {
        li {
          margin-left: 10px;
      
          &::before {
            content: '\f114';
          }
        }
      
        .category-list-item .category-list-count {
          float: right;
          margin-right: 20px;
          border-radius: 6px;
          width: 2em;
          text-align: center
        }
      }
    }
  }

  #krw-toc {
    // Use important to prevent its style being by hbe decrypt func 
    // (https://github.com/D0n9X1n/hexo-blog-encrypt/blob/7a820d3bd1b2d1f6aa748f870230f892fd72b330/lib/hbe.js#L213-L218)
    display: block!important;

    .toc {
      padding: 0;
      overflow: auto;
    }
    .toc-item {
      > a {
        display: block;
        padding-left: 1em;
        position: relative;
      }

      &:not(.show)>.toc-child {
        display: none;
      }

      &.active {
        > a {
          color: #fff;
          background: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
    
          &::before {
            position: absolute;
            width: 0;
            height: 0;
            content: "";
            top: 10px;
            margin: auto;
            left: 0;
            border-left: 6px solid;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }

      &.active > a::before {
        border-left-color: #fff;
      }
    }
  }
}

.widget-kratos-toc, .kratos-post-inner-toc {
  .toc-child {
    padding-left: 1rem;
  }
}

body.nav-up #kratos-widget-area > .sticky-area {
  top: 15px;
}

.toc li {
  list-style: none;
  padding: 0;
}

.kr-tool {
  .tool, .box {
    right: 20px;
    -webkit-transition: .5s;
    transition: .5s;
  }

  .tool {
    bottom: 20px;
    position: fixed;
  }

  .box {
    background: #848484;
    margin-top: 10px;
    opacity: .7;

    &:hover {
      background: #656565;
      cursor: pointer;
    }

    span {
      width: 30px;
      height: 30px;
      display: table-cell;
      vertical-align: middle;
      color: #fff;
      font-size: 15px;
    }

    & a {
      display: contents;
    }

    &.gotop-box {
      bottom: -50px;
      position: fixed;
    }
  }
}

body.scroll-down .kr-tool {
  .tool {
    bottom: 60px;
  }
  .box.gotop-box {
    bottom: 20px;
  }
}

#footer {
  background: #23282d;
  padding-top: 40px;

  &.ap-lrc {
    padding-bottom: 32px;
  }

  li {
    letter-spacing: 1px;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-block;

    a {
      color: rgba(255, 255, 255, .5);
    }
  }

  .kratos-social-icons {
    padding-left: 0;
  
    li {
      padding: 4px 14px;
  
      a {
        display: inline-block;
        text-decoration: none;

        > i {
          font-size: 36px;
        }

        > :not(i) {
          width: 36px;
          height: 36px;
        }

        > * {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .kratos-copyright {
    padding-left: 0;
    list-style: none;
  
    div {
      line-height: 1.2;
      margin: 0 40px;
    }
  
    li {
      color: rgba(255, 255, 255, .3);
      line-height: 1;
      margin: 0 2px;
      font-size: 12px;
    }
  
    &:first-child {
      margin-top: 25px;
    }
  }
}

@media(min-width:992px) {
  #footer .col-md-6 {
    width: 80%;
    margin: auto 10%;
  }
}

.linkpage {
  ul {
    color: rgba(255, 255, 255, .15);
    padding: 0;
  
    &:after {
      content: " ";
      clear: both;
      display: block;
    }

    li {
      float: left;
      width: 48%;
      position: relative;
      transition: all .3s ease-out;
      border-radius: 5px;
      line-height: 1.3;
      height: 90px;
      display: block;

      &:hover {
        background: rgba(230, 244, 250, .5);
        cursor: pointer;
      }

      a {
        padding: 0 10px 0 90px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
          position: absolute;
          top: 15px;
          left: 15px;
          cursor: pointer;
          margin: auto;
          border: none;
        }

        h4 {
          color: #333;
          font-size: 18px;
          margin: 0 0 7px 0;
          padding-left: 90px;

          
        }

        p {
          font-size: 12px;
          color: #999;
          padding-left: 75px
        }

        h4, p {
          cursor: pointer;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 1.4;
        }

        &:hover {
          h4 {
            color: #51aded;
          }
        }
      }
    }
  }

  h3 {
    margin: 15px -25px;
    padding: 0 25px;
    border-left: 5px solid #51aded;
    background-color: #f7f7f7;
    font-size: 25px;
    line-height: 40px;
  }
}

@media(max-width:1200px) {
  .kratos-entry-content-new {
    height: 80px;
  }
}

@media(max-width:992px) {
  .kratos-post-meta span {
    font-size: 12px;
    i:not(:first-child) {
      margin-left: 10px;
    }
  }

  .kratos-entry-content-new {
    height: 100px;
  }

  .title-h2 {
    padding: 0 15px;
    font-size: 18px
  }
}

@media(max-width:768px) {
  h {
    &1 {
      font-size: 25px !important;
    }
    &2 {
      font-size: 21px;
    }
    &3 {
      font-size: 18px;
    }
    &4 {
      font-size: 15px;
    }
  }

  .kratos-nav-toggle {
    display: block;
  }

  .color-logo {
    text-align: center;
    width: 100%;
    font-size: 25px;
    font-weight: 400;
    height: 60px;
    padding: 7px;

    a, a:hover {
      color: #fff !important;
    }

    img {
      max-width: 175px;
      height: 35px
    }
  }

  .kratos-cover {
    height: inherit;
    padding: 3em 0;

    &.kratos-cover-2.text-center {
      display: none;
    }
  }

  .kratos-start {
    height: 45px !important;
  }

  .kratos-overlay {
    display: none;
  }

  #kratos-header {
    background: rgba(22, 23, 26, .9)
  }

  #kratos-blog-post {
    padding: 2em 0 1em;
  }

  .kratos-post-meta span {
    font-size: 9pt;

    i:not(:first-child) {
      margin-left: 5px
    }
  }

  #kratos-menu-wrap, .kratos-post-inner-new header .label {
    display: none;
  }

  .kratos-post-content embed {
    height: auto;
  }

  h1.kratos-entry-title.text-center {
    font-size: 36px !important;
  }

  .kratos-entry-thumb-new {
    position: relative;
    float: none;
    width: auto;
    height: auto;
    margin: 0 0 20px 0;
    padding: 0 0 60% 0;
    img {
      padding: 0;
      border: 0;
    }
  }

  .kratos-post-inner-new {
    margin-left: 0;
    height: auto;
    padding: 0 20px;
  }

  .kratos-post-meta-new .read-more {
    margin-right: 8px;
  }

  .kratos-entry-content-new {
    height: auto;
  }

  .hide_notice, .info {
    font-size: 12px;
  }

  #archives .arc-t {
    width: 45%;
  }
}

@media(max-width:768px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media(max-width:530px) {
  #slide .carousel-indicators {
    margin-bottom: 0;
  }

  .kratos-entry-footer .post-like-donate a {
    width: 100px;
  }

  .kratos-entry-footer .footer-tag a {
    padding: 2px 4px;
  }

  .kratos-post-meta .hd,
  .pull-date,
  .spig,
  .useragent .bversion {
    display: none;
  }

  .pagination li a {
    width: 30px;
    padding: 4px;
    margin-left: 5px;
  }

  #archives .arc-t {
    width: 97%;
  }
}

@media(max-width:460px) {
  .linkpage ul li {
    width: 97%;
  }

  .linkpage ul {
    padding-left: 5px;
  }
}

@media(min-width:768px) {

  .container {
    width: 750px;
  }

  #kratos-desktop-topnav.color-banner {
    height: 71px;
  }

  .kratos-start.kratos-hero {
    height: 70px;
  }

  .color-banner #kratos-menu-wrap {
    float: right;
    width: 80%
  }

  .color-banner .nav-header {
    padding: 14px 0;
  }

  #kratos-mobile-topnav {
    display: none;
  }

  .pagination>li>a {
    background-color: rgba(255, 255, 255, .8);
  }

  .kratos-hentry,
  .navigation div,
  #kratos-widget-area .widget {
    background-color: rgba(255, 255, 255, .8);
  }
}

@media(min-width:768px) and (max-width:991px) {
  .visible-sm {
    display: block !important;
  }

  .hidden-sm {
    display: none !important;
  }
}

@media(min-width:992px) {
  .container {
    width: 970px;
  }

  [class*=col-md-] {
    float: left
  }

  .col-md {
    &-1 {
      width: 8.33333333%;
    }
    &-2 {
      width: 16.66666667%;
    }
    &-3 {
      width: 25%;
    }
    &-4 {
      width: 33.33333333%;
    }
    &-5 {
      width: 41.66666667%;
    }
    &-6 {
      width: 50%;
    }
    &-7 {
      width: 58.33333333%;
    }
    &-8 {
      width: 66.66666667%;
    }
    &-9 {
      width: 75%;
    }
    &-10 {
      width: 83.33333333%;
    }
    &-11 {
      width: 91.66666667%;
    }
    &-12 {
      width: 100%;
    }
  }

  .page404 .errtxt {
    margin-top: 15%;
    h5, p {
      margin-top: 30px;
    }
  }
}

@media(min-width:992px) and (max-width:1199px) {
  .visible-md {
    display: block !important
  }

  .hidden-md {
    display: none !important
  }
}

@media(min-width:1200px) {
  .container {
    width: 1170px
  }

  .visible-lg {
    display: block !important
  }

  .hidden-lg {
    display: none !important
  }
}

@media print {
  body {
    background: #fff !important;
  }

  .col-md-8,
  #main,
  .kratos-post-inner {
    padding: 0
  }

  #kratos-header,
  .kratos-start,
  #kratos-widget-area,
  .kr-tool {
    display: none
  }
}

td.code {
  text-align: left;
}

.twitter-tweet {
  margin: auto;
}

#dsqjs {
  padding: 10px 15px;

  &-msg {
    color: #2a2e2e;
    background-color: #ebeef2;
    padding: 10px;
    border-radius: 5px;
  }
}

.post-comments {
  margin-top: 16px;
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .1);
  word-break: break-all;
}

.index-post {
  h1 {
    margin: 0 0 15px;
  }

  .kratos-entry-title {
    font-size: 36px;
    position: relative;
  }

  .kratos-entry-header .kratos-entry-title::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -ms-transform: scaleX(0);
    -o-transform: scaleX(0);
    transform: scaleX(0);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
  }

  .kratos-entry-header .kratos-entry-title:hover::after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    -o-transform: scaleX(1);
    transform: scaleX(1);
  }

  .kratos-entry-thumb-new {
    position: relative;
    float: none;
    width: auto;
    height: auto;
    margin: 0 0 20px 0;
    padding: 0 0 60% 0;
    img {
      padding: 0;
      border: 0
    }
  }

}

span.blur {
  text-shadow: 0 0 .5em rgb(0, 0, 0);
  color: rgba(1, 1, 1, .1)!important;
  transition: .2s;

  &:hover {
    text-shadow: 0 0 0;
    color:#000000!important;
  }
}


/**************** Search Page related ****************/
#kr-search-notice.alert {
  transition:.3s
}

#result-posts {
  margin-top: 20px;

  .kr-search-result {
    margin:auto;
    height:auto;
    width:90%;
    padding-top: 22px;

    .kratos-entry-content-new {
      height: auto;
    }
  }

  m {
    color:#333;
    background-color:yellow;
  }
}

#search-input {
  -webkit-appearance: none;
  border-color: #dbdbdb;
  background-color: #fff;
  border-radius: 4px 0 0 4px;

  &:hover {
    border-color: #b5b5b5;
  }

  &:focus {
    outline: 0;
  }

  &:active, &:focus {
    border-color: #3273dc;
    box-shadow: 0 0 0 .125em rgba(50,115,220,.25)
  }
}

.input-group {
  .form-input.input-lg {
    display: inline-flex;
    box-shadow: none;
    flex: auto;
    justify-content: flex-start;
    padding: 0 8px;
    font-size: 15px;
    max-width: 100%;
    border: 1px solid transparent;
  }
  .btn.btn-primary {
    border: transparent;
    border-radius: 0 4px 4px 0;
  }
}

.search-demo-tags a {
  margin: 5px 10px;
}

/**************** Plugins *****************/
span.mjx-chtml.MJXc-display {
  color: #000000;
}

#hexo-blog-encrypt {
  label {
    padding-left: 10px;
    pointer-events: none;
  }

  #hbePass {
    padding: 0;
    border-radius: 4px;
  }
}

mjx-container {
  display: inline-block;
  vertical-align: bottom;
  overflow-x: auto;
  max-width: 100%;
}

/******************* Images ********************/
body.custom-background {
  background-size: cover;
  background-attachment: fixed;
}

@import './kr-color/default.scss';